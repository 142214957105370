<template>
  <v-container
    class="pt-0 pb-0"
    :class="{ 'pr-0 pl-0': !$vuetify.breakpoint.mdAndUp }"
  >
    <v-row>
      <v-col cols="12">
        <p class="text-h6">Crear código de descuento</p>
      </v-col>
      <v-col cols="12">
        <v-card width="100%" class="card_background">
          <v-container>
            <p>Código de descuento</p>
            <v-text-field
              outlined
              label="ej: OFERTANAVIDEÑA"
              hide-details
            ></v-text-field>
            <p class="ma-0 mt-1 px-1 caption text--secondary">
              Tus clientes introduciran este código de descuento en la pantalla
              de pago
            </p>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card width="100%" class="card_background">
          <v-container>
            <p>Tipo de descuento</p>
            <v-expansion-panels flat mandatory @change="changeTypeMethod">
              <v-expansion-panel
                :key="0"
                style="border: 1px solid rgba(0, 0, 0, 0.42);"
              >
                <v-expansion-panel-header expand-icon="">
                  <v-radio-group
                    :value="typeDiscount === 0 ? 0 : null"
                    hide-details
                    class="pa-0 ma-0 d-flex"
                  >
                    <v-radio
                      label="Descuento por porcentaje"
                      readonly
                    ></v-radio>
                  </v-radio-group>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div>
                    <p class="ma-0 caption">Porcentaje de descuento</p>
                  </div>
                  <v-text-field
                    outlined
                    placeholder="%"
                    hide-details
                    class="mb-2"
                  ></v-text-field>
                  <v-divider class="my-4"></v-divider>
                  <p class="ma-0 mb-1">Aplicarlo a</p>
                  <v-radio-group
                    v-model="applyTo"
                    class="my-2 mb-3"
                    hide-details
                  >
                    <v-radio
                      :key="1"
                      label="Todos los productos"
                      value="all"
                    ></v-radio>
                    <v-radio
                      :key="2"
                      label="Productos espesificos"
                      value="specificProduct"
                    ></v-radio>
                  </v-radio-group>
                  <v-slide-x-transition>
                    <div v-if="applyTo === 'specificProduct'">
                      <v-autocomplete
                        v-model="autocompleteProduct"
                        :items="items"
                        return-object
                        item-text="name_product"
                        hide-details
                        @change="changeAuto"
                        outlined
                        dense
                        label="Buscar producto"
                      ></v-autocomplete>
                      <v-list class="pb-0">
                        <template v-for="(item, index) in productWithDiscount">
                          <v-card
                            class="card_background my-1"
                            :key="item.price"
                          >
                            <v-list-item class="pr-0">
                              <v-list-item-content>
                                <v-list-item-title
                                  >{{ item.name_product }}
                                </v-list-item-title>
                                <v-list-item-subtitle class="caption">
                                  {{ item.description }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  class="d-flex justify-space-between"
                                >
                                  <span class="font-weight-bold"
                                    >$ {{ item.price }}</span
                                  ><span class="mr-4">{{
                                    item.total_products | nameProductParts
                                  }}</span>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle></v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-avatar class="ma-0">
                                <v-btn icon small @click="deleteProdudct(index)"
                                  ><v-icon>delete</v-icon></v-btn
                                >
                              </v-list-item-avatar>
                            </v-list-item>
                          </v-card>
                        </template>
                      </v-list>
                    </div>
                  </v-slide-x-transition>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                :key="1"
                style="border: 1px solid rgba(0, 0, 0, 0.42);"
              >
                <v-expansion-panel-header expand-icon="">
                  <v-radio-group
                    :value="typeDiscount === 1 ? 0 : null"
                    hide-details
                    class="pa-0 ma-0 d-flex"
                  >
                    <v-radio
                      label="Descuento por monto fijo"
                      readonly
                    ></v-radio>
                  </v-radio-group>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div>
                    <p class="ma-0 caption">Monto del descuento</p>
                  </div>
                  <v-text-field
                    outlined
                    placeholder="%"
                    hide-details
                    class="mb-2"
                  ></v-text-field>
                  <v-divider class="my-4"></v-divider>
                  <p class="ma-0 mb-1">Aplicarlo a</p>
                  <v-radio-group
                    v-model="applyTo"
                    class="my-2 mb-3"
                    hide-details
                  >
                    <v-radio
                      :key="1"
                      label="Todos los productos"
                      value="all"
                    ></v-radio>
                    <v-radio
                      :key="2"
                      label="Productos espesificos"
                      value="specificProduct"
                    ></v-radio>
                  </v-radio-group>
                  <v-slide-x-transition>
                    <div v-if="applyTo === 'specificProduct'">
                      <v-autocomplete
                        v-model="autocompleteProduct"
                        :items="items"
                        return-object
                        item-text="name_product"
                        hide-details
                        @change="changeAuto"
                        outlined
                        dense
                        label="Buscar producto"
                      ></v-autocomplete>
                      <v-list class="pb-0">
                        <template v-for="(item, index) in productWithDiscount">
                          <v-card
                            class="card_background my-1"
                            :key="item.price"
                          >
                            <v-list-item class="pr-0">
                              <v-list-item-content>
                                <v-list-item-title
                                  >{{ item.name_product }}
                                </v-list-item-title>
                                <v-list-item-subtitle class="caption">
                                  {{ item.description }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  class="d-flex justify-space-between"
                                >
                                  <span class="font-weight-bold"
                                    >$ {{ item.price }}</span
                                  ><span class="mr-4">{{
                                    item.total_products | nameProductParts
                                  }}</span>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle></v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-avatar class="ma-0">
                                <v-btn icon small @click="deleteProdudct(index)"
                                  ><v-icon>delete</v-icon></v-btn
                                >
                              </v-list-item-avatar>
                            </v-list-item>
                          </v-card>
                        </template>
                      </v-list>
                    </div>
                  </v-slide-x-transition>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card width="100%" class="card_background">
          <v-container>
            <p class="mb-2">Requisitos mínimos</p>
            <v-radio-group v-model="minRequired" class="my-2 mb-3" hide-details>
              <v-radio :key="1" label="Ninguno" value="none"></v-radio>
              <v-radio
                :key="2"
                label="Monto mínimo de compra"
                value="min_amount"
              ></v-radio>
              <v-slide-x-transition>
                <v-container class="pt-0" v-if="minRequired === 'min_amount'">
                  <v-text-field
                    outlined
                    label="$ Cantidad"
                    hide-details
                  ></v-text-field>
                </v-container>
              </v-slide-x-transition>
              <v-radio
                :key="3"
                label="Cantidad mínima de artículos"
                value="min_products"
              ></v-radio>
              <v-slide-x-transition>
                <v-container class="pt-0" v-if="minRequired === 'min_products'">
                  <v-text-field
                    outlined
                    label="% Porcentaje"
                    hide-details
                  ></v-text-field>
                </v-container>
              </v-slide-x-transition>
            </v-radio-group>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      typeDiscount: null,
      applyTo: "all",
      items: [
        {
          name_product: "Produc 1",
          description: "Este es un producto con una descripcion grnade",
          price: 12,
          barcode: 1,
          total_products: 2
        },
        {
          name_product: "Produc 2",
          description: "Produc 2",
          price: 212,
          barcode: 2,
          total_products: 2
        },
        {
          name_product: "Produc 3",
          description: "Produc 3",
          price: 412,
          barcode: 3,
          total_products: 2
        }
      ],
      autocompleteProduct: null,
      productWithDiscount: [],
      // :: Minimum requirements
      minRequired: "none"
    };
  },
  methods: {
    changeTypeMethod(value) {
      this.typeDiscount = value;
      this.applyTo = "all";
      this.autocompleteProduct = null;
      this.productWithDiscount = [];
    },
    changeAuto(value) {
      this.autocompleteProduct = "";
      if (value) {
        const exist = this.productWithDiscount.some(
          item => item.barcode === value.barcode
        );
        if (!exist) this.productWithDiscount.push({ ...value });
      }
    },
    deleteProdudct(index) {
      this.productWithDiscount.splice(index, 1);
    }
  },
  filters: {
    nameProductParts(parts) {
      if (parts) {
        if (parts === 1) return parts + " Pieza";
        else return parts + " Piezas";
      } else {
        return "N/A";
      }
    }
  }
};
</script>
<style>
.v-rating .v-icon {
  padding: 0 !important;
}
</style>
